import React from "react"


import SEO from "components/seo"
import Layout from "components/layout"
import { GlobalCover } from "components/global/cover/cover"
import { ReportsList } from "components/pages/investor/reports/list"

const Reports = ({ pageContext, location }) => {
	const lang = pageContext.langKey
	// const data = pageContext.data

	const { seo, fileCategories, backgroundColor } = lang === "en"
		? pageContext?.data
		: pageContext?.data?.translation
		|| pageContext?.data

	const SEODefaultImg = fileCategories?.cover?.image?.sourceUrl
	const pdfFiles = pageContext?.data?.pdfFiles?.nodes

	return (
		<Layout theme={backgroundColor.backgroundColor.color || "light"} path={location.pathname} lang={lang}>
			<SEO
				title={seo?.title}
				desc={seo?.metaDesc}
				fbImg={SEODefaultImg}
				fbTitle={seo?.opengraphTitle}
				fbDesc={seo?.opengraphDescription}
				url={location?.href}
				twitterTitle={seo?.twitterTitle}
				twitterDesc={seo?.twitterDescription}
				twitterImg={SEODefaultImg}
			/>
			<GlobalCover theme={backgroundColor.backgroundColor.color || "light"} data={fileCategories?.cover} breadcrumb={location.pathname} />
			<ReportsList data={pdfFiles} lang={lang} />
		</Layout>
	)
}

export default Reports