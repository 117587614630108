import React from "react"
import PropTypes from "prop-types"

import AccordionMaterial from "@material-ui/core/Accordion"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import AccordionDetails from "@material-ui/core/AccordionDetails"

export const Accordion = ({
  forwardRef,
  id,
  expandIcon,
  expandIconPlus,
  title,
  titleClassName,
  className,
  headerClassName,
  contentClassName,
  defaultActiveKey,
  children,
}) => {
  return (
    <AccordionMaterial
      className={`accordion ${className}`}
      id={id}
      ref={forwardRef}
      defaultActiveKey={defaultActiveKey}
    >
      <AccordionSummary
        expandIcon={expandIcon}
        className={`accordion-header ${headerClassName} ${expandIconPlus &&
          "accordion-header-plus"}`}
      >
        <h4 className={`accordion-title ${titleClassName}`}>{title}</h4>
        {expandIconPlus && (
          <div className="expand-plus">
            <div className="h-bar" />
            <div className="v-bar" />
          </div>
        )}
      </AccordionSummary>
      <AccordionDetails className={`accordion-content ${contentClassName}`}>
        {children}
      </AccordionDetails>
    </AccordionMaterial>
  )
}

Accordion.propTypes = {
  forwardRef: PropTypes.any,
  expandIcon: PropTypes.any,
  expandIconPlus: PropTypes.bool,
  id: PropTypes.string,
  onClick: PropTypes.any,
  children: PropTypes.any,
  className: PropTypes.string,
  headerClassName: PropTypes.string,
  contentClassName: PropTypes.string,
}

Accordion.defaultProps = {
  expandIcon: <i className="air ai-angle-down" />,
  title: "Title",
  titleClassName: "",
  className: "",
  headerClassName: "",
  contentClassName: "",
}
