import React, { useEffect, useState } from "react"
import moment from "moment"
import { Accordion } from "components/anti/accordion/accordion"
// import { useScrollAnim } from "src/components/hooks/hooks"
import { Button } from "components/anti/buttons/buttons"

import pdf from "assets/img/pdf-file.svg"

export const ReportsList = ({ data, lang }) => {
  const rootUrl = process.env.GATSBY_ROOT_URL

  // const [trigger, anim] = useScrollAnim()

  const [allReports, setAllReports] = useState([])

  const allYear = [...data.map(data => data.pdfFiles.year)]
  const allYearFiltered = [...new Set(allYear.sort((a, b) => b - a))]

  useEffect(() => {
    let reports = [
      ...allYearFiltered.map(year => ({ year: year, listOfReports: [] })),
    ]
    data.map(report => {
      const year = report.pdfFiles.year
      reports.forEach(reportData => {
        if (reportData.year === year) {
          let eachData = {
            date: report.date,
            pdfFiles: report.pdfFiles,
            title: report.title,
          }
          reportData["listOfReports"].push(eachData)
        }
      })
    })
    setAllReports(reports)
  }, [])

  const dataLayerDownload = title => {
    window?.dataLayer?.push({
      event: "generalEvent",
      event_category: "Download Report",
      event_action: "View Report",
      event_category: { title },
    })
  }

  const dataLayerView = title => {
    window?.dataLayer?.push({
      event: "generalEvent",
      event_category: "View Report",
      event_action: "View Report",
      event_category: { title },
    })
  }

  return (
    <section className="py-main sc-corporateGovernance-piagam">
      <div className="container">
        {allReports.map((year, i) => {
          return (
            <>
              <div className={`pb-md-5 year-reports d-none d-md-block`} key={i}>
                <h2 className="mb-4">{year.year}</h2>
                <div className="row row-4">
                  {year?.listOfReports?.map((report, j) => {
                    const idFile = report.pdfFiles.file.databaseId
                    return (
                      <div className={`col-lg-6`} key={i}>
                        <div className="piagam d-flex pb-2 mb-4">
                          <img
                            src={pdf}
                            className="img-fluid h-100 w-100px mr-3"
                            alt="pdf"
                          />
                          <div className="d-flex flex-column justify-content-center w-100">
                            <h4>{report.title}</h4>
                            <div>
                              <p className=" d-block d-md-none text-muted">
                                {moment(report.date).format(
                                  "DD MMM YYYY, hh:mm A"
                                )}
                              </p>
                              <div className="d-flex justify-content-between flex-wrap">
                                <p className=" d-md-block d-none text-muted">
                                  {moment(report.date).format(
                                    "DD MMM YYYY, hh:mm A"
                                  )}
                                </p>
                                <div className="btn-group">
                                  <a
                                    className="btn btn-link btn-control"
                                    href={report.pdfFiles.file.mediaItemUrl}
                                    target="_blank"
                                    onClick={() => dataLayerView(report.title)}
                                  >
                                    {lang === "en" ? "View" : "Lihat"}
                                  </a>
                                  <a
                                    className="btn btn-link btn-control ml-4"
                                    href={`${rootUrl}/download/index.php?id=${idFile}`}
                                    target="_blank"
                                    onClick={() =>
                                      dataLayerDownload(report.title)
                                    }
                                  >
                                    {lang === "en" ? "Download" : "Unduh"}
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
              <div className="d-block d-md-none">
                <Accordion
                  title={`${year.year}`}
                  defaultExpanded={false}
                  className="my-4 mobile-repor"
                  titleClassName="text-black accordion-reports h2"
                >
                  <div className="year-reports" key={i}>
                    <div className="row">
                      {year?.listOfReports?.map((report, i) => {
                        const idFile = report.pdfFiles.file.databaseId
                        return (
                          <div className={`col-lg-6`} key={i}>
                            <div className="piagam d-flex pb-2 mb-4">
                              <img
                                src={pdf}
                                className="img-fluid h-100 w-100px mr-3"
                                alt="pdf"
                              />
                              <div className="col-9 col-md-10">
                                <h4>{report.title}</h4>
                                <p className=" d-block d-md-none text-muted">
                                  {moment(report.date).format(
                                    "DD MMM YYYY, hh:mm A"
                                  )}
                                </p>
                                <div className="d-flex justify-content-between flex-wrap">
                                  <p className=" d-md-block d-none text-muted">
                                    {moment(report.date).format(
                                      "DD MMM YYYY, hh:mm A"
                                    )}
                                  </p>
                                  <div className="btn-group">
                                    <a
                                      className="btn btn-link btn-control"
                                      href={report.pdfFiles.file.mediaItemUrl}
                                      target="_blank"
                                      onClick={() =>
                                        dataLayerView(report.title)
                                      }
                                    >
                                      {lang === "en" ? "View" : "Lihat"}
                                    </a>
                                    <a
                                      className="btn btn-link btn-control ml-4"
                                      href={`${rootUrl}/download/index.php?id=${idFile}`}
                                      target="_blank"
                                      onClick={() =>
                                        dataLayerDownload(report.title)
                                      }
                                    >
                                      {lang === "en" ? "Download" : "Unduh"}
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </Accordion>
              </div>
            </>
          )
        })}
      </div>
    </section>
  )
}
